<template>
<section>
    <b-button class="btn btn-primary" v-b-modal.addPost>Créer un article</b-button>

    <b-modal id="addPost" title="Créer un article" hide-footer>
        <!-- Titre -->
        <b-form-group id="name" label="Titre de l'article" label-for="example-input-1">
            <b-form-input
            id="example-input-1"
            type="text"
            name="example-input-1"
            v-model="title"
            aria-describedby="input-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">
            Ce champ est obligatoire.
            </b-form-invalid-feedback>
        </b-form-group>
        <!-- categorie -->
        <b-form-group id="name" label="Type de poste" label-for="example-input-1">
             <b-form-select v-model="post_category" :options="categories_options"></b-form-select>
            <b-form-invalid-feedback id="input-1-live-feedback">
            Ce champ est obligatoire.
            </b-form-invalid-feedback>
        </b-form-group>
        <!-- tags -->
        <b-form-group id="tags" label="Tags" label-for="example-input-1">
            <b-form-tags input-id="tags" v-model="tags"></b-form-tags>
            <b-form-invalid-feedback id="input-1-live-feedback">
            Ce champ est obligatoire.
            </b-form-invalid-feedback>
        </b-form-group>
        <!-- is_published -->
        <b-form-group id="is_published" label="Publier" label-for="example-input-1">
            <b-form-checkbox
                id="is_published"
                v-model="is_published"
                name="is_published"
                >
                </b-form-checkbox>
            <b-form-invalid-feedback id="input-1-live-feedback">
            Ce champ est obligatoire.
            </b-form-invalid-feedback>
        </b-form-group>
        <hr>
        <div class="d-flex justify-content-end">
            <button class="btn btn-link">Annuler</button>
            <button class="btn-success btn" @click="handleSubmit()">Ajouter</button>
        </div>
    </b-modal>
</section>
</template>
<script>
import axios from 'axios'
export default {
    name:'add-post',
    data(){
        return{
            title:'',
            post_category:'',
            tags:[],
            is_published:true,
            categories_options:[
                { value: 'article', text: 'Article'},
                { value: 'blog', text: 'Blog' }
            ]
        }
    },
    methods:{
        handleSubmit(){
            this.postPost()
        },
        postPost(){
            let body = {
                // user: "01G5DVN6ZDHVR6PHYZZFWZN3VW", //ulid user à envoyer dans le vuex après
                title: this.title,
                post_category:this.post_category,
                post_start_at: "2022-08-03 09:41:25",
                post_end_at: "2023-11-18 09:41:25",
                is_published:this.is_published,
                tags:this.tags
            }
            axios.post('posts', body, {
                headers:{
                    'X-AUTH-TOKEN':localStorage.getItem('token')
                }
            })
            .then(resPost=> {
                console.log(resPost);
                this.$bvModal.hide("addPost")
                this.$emit('refresh_posts')
            })
            .catch(errPost=> console.log(errPost))
        }
    }
}
</script>