<template>
    <section>
        <div class="page-header">
            <h3 class="page-title" data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="2000" >
                <span class="page-title-icon bg-gradient-primary text-white mr-2">
                <i class="mdi mdi-newspaper"></i>
                </span> Articles
            </h3>
            <nav aria-label="breadcrumb">
                <ul class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                    <span></span>articles<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                </li>
                </ul>
            </nav>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-end mb-5">
                            <!-- ajout d'un article -->
                            <addPost @refresh_posts="getPosts"/>
                            <!-- <button class="btn btn-primary" @click="goToAddPost()">Créer un blog</button> -->
                        </div>
                        <h4 class="card-title">Liste des articles</h4>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Créateur</th>
                                        <th>Titre du blog</th>
                                        <th>Status</th>
                                        <th>Date de publication</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="post in posts" :key="post.ulid">
                                        <td>
                                            <!-- image -->
                                            <template>
                                            <picture v-b-modal="post.ulid" v-if="post.image">
                                                <source :srcset="post.image.content.webpImage" type="image/webp">
                                                <img class="category-image" :src="post.image.content.originalImage" alt="">
                                            </picture>
                                            <img v-else v-b-modal="post.ulid" class="category-image" src="https://fakeimg.pl/300x200"  alt="Card image">

                                            <!-- modal image -->
                                            <b-modal :id="post.ulid" size="lg" hide-footer hide-header>
                                                <div class="d-flex justify-content-end mb-3">
                                                    <add-image
                                                    :modal="'addImageCategorie' + post.ulid "
                                                    @saveImage="saveImage(post, $event['ulid'])"
                                                    />
                                                </div>
                                                <picture v-b-modal="post.ulid" v-if="post.image">
                                                    <source :srcset="post.image.content.webpImage" type="image/webp">
                                                    <img class="product_image" :src="post.image.content.originalImage" alt="">
                                                </picture>
                                                <img v-else class="product_image" src="https://fakeimg.pl/300x200"  alt="Card image">
                                            </b-modal>
                                            </template>
                                        </td>
                                        <td class="pointer" @click="goToPostDetails(post.ulid)">{{post.user.pseudo}}</td>
                                        <td>{{post.title}}</td>
                                        <td>
                                            <label data-v-f2b16ec4="" class="badge badge-gradient-success">Publiée</label>
                                        </td>
                                        <td>{{new Date(post.datepublished).toLocaleString('Fr-fr')}}</td>
                                        <td><i class="fa fa-trash pointer" @click="alertDeletePost(post.ulid)"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import addPost from '../../../../components/functions/add-post'
import addImage from '../../../../components/functions/add-image.vue'
export default {
    name:"posts",
    components:{addPost, addImage},
    data(){
        return {
            posts:[]
        }
    },
    methods:{
        goToAddPost(){
            this.$router.push({
                name:'addPost'
            })
        },
        getPosts(){
            axios.get('posts')
            .then(resGetPosts => {
                this.posts = resGetPosts.data.result
            })
            .catch(errGetPosts => console.log(errGetPosts))
        },
        alertDeletePost(ulid){
        this.$swal({
            title: 'Êtes-vous sûr?',
            text: 'Voulez-vous supprimer cet article?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Supprimer',
            cancelButtonText: 'Annuler',
            })
            .then((result) => {
                if (result.value) {
                this.isload =true
                this.deletePost(ulid)
                }
            })
        },
        deletePost(ulid){
            axios.delete(`posts/${ulid}`)
            .then(resDeletePost => {
                console.log(resDeletePost);
                this.getPosts()
            })
            .catch(errDeletePost => console.log(errDeletePost))
        },
        saveImage(post,ulidImage,modalId){
            this.showpageLoader = true
            axios.put(`posts/${post.ulid}`, {
                image: ulidImage,
                },
                {
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
                .then(()=> {
                    this.$bvModal.hide(modalId)
                    this.getPosts()
                })
                .catch(err => console.log ('errorPUT', err))
            },
        goToPostDetails(ulid){
            this.$router.push({
                name:'postDetails',
                params:{
                    ulid:ulid
                }
            })
        }
    },
    mounted(){
        this.getPosts()
    }
}
</script>
<style scoped>
.product_image{
    width:100%;
}

</style>